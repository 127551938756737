import { Box } from "@mui/material";
import FooterBtn from "../FooterBtn";
import { useEffect, useState } from "react";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import speedDatingService from "services/speedDatingService";
import { useSelector } from "react-redux";
import { getUserProfile } from "redux/profile/selectors";

export default function AddPayment({ speedData = {} }) {
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(null);
	const { navigateToPayVideoPage } = useNavigator();

	const { gender } = useSelector(getUserProfile);
	const getPayment = async () => {
		if (!speedData?.key) return;

		const res = await speedDatingService.getSpeedDatingPaymentUrl(speedData?.key);
		if (res.data?.success) {
			navigateToPayVideoPage({ urlName: speedData.urlName, iframeUrlLink: res.data?.url });
		}
		setLoading(false);
	};

	const handleClick = () => {
		setLoading(true);
		getPayment();
	};

	useEffect(() => {
		console.log("gender", gender);

		//setPrice(1);
		if (gender === 1) {
			setType(speedData?.paymentTypeFemale);
		} else if (gender == 0) {
			setType(speedData?.paymentTypeMale);
		}
	}, [speedData, gender]);

	return (
		<>
			{type && type === "PAID" && (
				<>
					<Box>
						<h3 className="title">{`עוד רגע ואתם שם..`}</h3>
					</Box>

					<Box className="text">
						<Box>
							{`האירוע אמנם ללא תשלום 🤗 אך, על מנת להבטיח שכולם יגיעו ויקחו חלק פעיל, האירוע מתקיים עם דמי רצינות בהזנת פרטי אשראי, כך שבמקרה של אי הגעה תחויבו ב${
								speedData?.price ?? ""
							}  ש"ח. `}
							<Box>
								<u>
									<b>{`אם תגיעו למפגשים, לא תחויבו כלל! `}</b>
								</u>
							</Box>
						</Box>
					</Box>
					<Box className="footer">
						<FooterBtn
							handleClick={handleClick}
							text={i18next.t("למעבר לתשלום דמי רצינות")}
							loading={loading}
						/>
					</Box>
				</>
			)}
			{type && type === "TOKEN" && (
				<>
					<Box>
						<h3 className="title">{`עוד רגע ואתם שם..`}</h3>
					</Box>

					<Box className="text">
						<Box>
							{`האירוע אמנם ללא תשלום 🤗 אך, על מנת להבטיח שכולם יגיעו ויקחו חלק פעיל, האירוע מתקיים עם דמי רצינות בהזנת פרטי אשראי, כך שבמקרה של אי הגעה תחויבו ב${
								speedData?.price ?? ""
							}  ש"ח. `}
							<Box>
								<u>
									<b>{`אם תגיעו למפגשים, לא תחויבו כלל! `}</b>
								</u>
							</Box>
						</Box>
					</Box>
					<Box className="footer">
						<FooterBtn
							handleClick={handleClick}
							text={i18next.t("למעבר לתשלום דמי רצינות")}
							loading={loading}
						/>
					</Box>
				</>
			)}
		</>
	);
}
