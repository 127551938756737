import { Button, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import usePopups from "hooks/usePopups";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPopups } from "redux/popups/selectors";
import { getUserProfile } from "redux/profile/selectors";

export default () => {
	const { closePopup } = usePopups();
	// const { data } = useSelector(getPopups);
	const user = useSelector(getUserProfile);
	const appName = process.env.REACT_APP_ENV_NAME;
	const textWhatsWait = [
		"אפשרות לראות אנשי קשר משותפים! הדרך הבטוחה והיעילה להכיר באמת!",
		"צ'אט מהיר סטייל וואטסאפ עם אישורי קריאה, הקלטה קולית ועוד.",
		"שליחת הודעות ללא צורך במאצ'!",
		"ובהמשך, אחרי שקצת נכיר אתכם - נציע לכם דייטים בוידאו אונליין, ובאמצעות הבינה המלאכותית שלנו גם הצעות מותאמות אישית לפי ההעדפות שלכם!",
	];
	const textWhatsAsk = [
		"להציג את עצמך באמת, עם התמונה, הגיל והנתונים האמיתיים שלך.",
		"לשמור על שפה נאותה אחד כלפי השני, מתוך כבוד הדדי. ",
		"לנהוג ברגישות, גם אם לא מקבלים תשובה כמו שציפינו. ",
		"לקבוע מפגשים בהתאם לכללי הבטיחות והזהירות המקובלים.",
	];

	const ulGenerator = (list) => {
		return (
			<ul>
				{list.map((text, index) => (
					<li key={index}>{text}</li>
				))}
			</ul>
		);
	};

	const listWhatsWaitForU = () => ulGenerator(textWhatsWait);
	const listWhatsWeAsk = () => ulGenerator(textWhatsAsk);

	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog enteredPopup">
			{/* <div className="user-chat-nav ">
				<Button
					color="none"
					type="button"
					onClick={closePopup}
					className="nav-btn"
					id="user-profile-hide">
					<i className="ri-close-line"></i>
				</Button>
			</div> */}
			<ModalHeader></ModalHeader>

			<ModalBody>
				<div className="wrapBox">
					{`היי${user?.name ? ` ${user?.name}` : ""}`} <br />
					איזה כיף… הגעת לשליש גן עדן - סמארט! אפליקציית דייטינג חכמה ובטוחה 🤓
					<br />
					<br />
					לתשומת לבך, מדובר באפליקציה חדשה, נפרדת ונוספת, מבית שליש גן עדן. ניתן לגלוש תמיד גם בשליש
					גן עדן בגרסה הישנה הפועל בכתובת
					<a href="https://www.date4dos.co.il"> date4dos.co.il</a>.
					<br />
					רגע לפני שמתחילים, חשוב לדעת שאנחנו מצפים מהמשתמשים שלנו לסטנדרט גבוה <br />
					📈
					<br />
					חשוב להציג את עצמך באמת, עם התמונה, הגיל והנתונים האמיתיים שלך, ולשמור על שפה נאותה אחד
					כלפי השני.
					<br />
					<br />
					בכל מקרה, אנחנו תמיד כאן בשבילך
					<span style={{ color: "red" }}> ❤</span>
					<br />
					<br />
				</div>
				{/* <div className="wrapBox">
					<span>{`איזה כיף… הגעת ל`}</span>
					<span style={{ fontWeight: "bold", color: "#2d5086" }}>{`${appName}!`}</span>
					<span
						style={{
							display: "inline-block",
						}}>{` כאן אנחנו מבקשים מהמשתמשים שלנו "לקפוץ למים", עד למציאת החצי השני 😍`}</span>
				</div>
				<div className="wrapBox">
					<span style={{ fontWeight: "bold" }}>{`אז מה מחכה לך ב`}</span>
					<span style={{ fontWeight: "bold" }}>{`${appName}:`}</span>
					{listWhatsWaitForU()}
				</div>
				<div className="wrapBox">
					<span>{`לתשומת לבך,`}</span>
					<span style={{ fontWeight: "bold", color: "#2d5086" }}>{`${appName} `}</span>
					<span>{`הינה אפליקציה חדשה, נפרדת ונוספת, מבית שליש גן עדן. ניתן לגלוש תמיד גם בשליש גן עדן המקורי הפועל בכתובת date4dos.co.il.`}</span>
				</div>
				<div className="wrapBox">
					<span
						style={{
							fontWeight: "bold",
						}}>{`מה אנחנו מבקשים מהמשתמשים שלנו :`}</span>
					{listWhatsWeAsk()}
				</div>
				<div style={{ color: "#0bc1c7", fontWeight: "600" }}>
					{`בכל מקרה, אנחנו תמיד כאן בשבילך, ואפשר וחשוב לדווח לנו על כל דבר חריג. ככה נוכל לשמור על הביטחון שלך ולעשות את המקסימום האפשרי מבחינתנו - וכמובן, לייצר כמה שיותר דייטים רציניים שיובילו אתכם לחופה ❤️`}
				</div> */}
			</ModalBody>
			<ModalFooter>
				<Button
					style={{ fontSize: "21px", width: "90%" }}
					outline
					size="sm"
					color="primary"
					onClick={closePopup}
					className="btn-rounded littleBtn enterdModal">
					{`הבנתי, אשמח להתחיל >>`}
				</Button>
			</ModalFooter>
		</Modal>
	);
};
