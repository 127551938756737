import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import usePopups from "hooks/usePopups";
import IframeContainer from "../SubscriptionModal/IframeContainer";
import { getUserProfile } from "redux/profile/selectors";
import ApiRequestStatus from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { getPopups } from "redux/popups/selectors";
import tokenService from "services/token.service";
import "assets/scss/popups/buyWithToken.scss";
import { Box } from "@mui/material";
import whiteLogo from "assets/images/newLogin/newLogo.png";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { purchasePromotionWithCardcom } from "redux/promotionsSale/actions";
import {
	getFetchPromotionCardcomApiStatus,
	getIsPurchasePromoteDone,
	getPromotionCardComUrl,
} from "redux/promotionsSale/selectors";

export default function PromotionsSaleModal() {
	const ref = useRef(false);

	const purchasePromoteStatus = useSelector(getIsPurchasePromoteDone);
	const fetchCardcomApiStatus = useSelector(getFetchPromotionCardcomApiStatus);
	const cardComUrl = useSelector(getPromotionCardComUrl);

	const [hasToken, setHasToken] = useState("");
	const [tokenData, setTokenData] = useState("");
	const [showIframe, setShowIframe] = useState(false);

	const { gender, name } = useSelector(getUserProfile);
	const { promotionSalePlan, openCardcom } = useSelector(getPopups);

	const dispatch = useDispatch();
	const { closePopup, showFittedMessagePopup, showBuyWithTokenPopup } = usePopups();

	const textFitted = (
		<div>
			<h2 style={{ fontWeight: "bold" }}>{`הרכישה בוצעה בהצלחה`}</h2>
			<div>{`איזה כיף! ברגע זה ממש אנחנו מתחילים לקדם אותך 😍`}</div>
			<div style={{ marginTop: "10px" }}>{`בהצלחה במציאת החצי השני!`}</div>
		</div>
	);

	const onContinuePurchase = () => {
		if (hasToken) {
			closePopup();
			return showBuyWithTokenPopup({
				tokenData,
				selectedPlan: promotionSalePlan,
				withoutReturn: true,
				isPromotion: true,
			});
		}

		dispatch(
			purchasePromotionWithCardcom(promotionSalePlan, () =>
				showFittedMessagePopup(textFitted, false)
			)
		);
		setShowIframe(true);
	};
	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "toolbar=0,location=0,menubar=0,width=600,height=600");
	};

	const getDayTimeText = (fullDay = false) => {
		const oneDay = fullDay ? "יום שלם" : "יום אחד";
		return promotionSalePlan?.daysCount == 1
			? oneDay
			: promotionSalePlan?.daysCount == 2
			? "יומיים"
			: `${promotionSalePlan?.daysCount} ימים`;
	};

	const getHeaderText = () => {
		return (
			<div style={{ fontSize: "22px", lineHeight: "1.3" }}>
				<b style={{ fontSize: "26px", fontWeight: "600" }}>{`${name}, מזמינים אותך לעוף גבוה!`}</b>
				<br />
				<b
					style={{
						fontSize: "26px",
						fontWeight: "600",
					}}>{`${getDayTimeText(true)} של בוסט לכרטיס שלך`}</b>
				<br />
				<b style={{ fontSize: "32px", marginTop: "30px", display: "block" }}>
					{`בעלות של ${promotionSalePlan?.price} ש"ח בלבד`}
				</b>
			</div>
		);
	};
	const getBodyText = () => {
		return (
			<div className="termsPurch" style={{ fontSize: "18px", marginTop: "30px" }}>
				{`${getDayTimeText()} של בוסט שמגביר את כמות ההופעות שלך אצל ${
					gender ? "משתמשים שמתאימים" : "משתמשות שמתאימות"
				} לך - ובכך מגדיל את הסיכויי שלך לקבל יותר פניות, יותר הודעות, ויותר הצעות לדייט!`}
				<br />
				{`ההצעה בתוקף לזמן מוגבל בלבד ובכפוף`}
				<a className="underLine" onClick={openInNewTab}>
					{" לתקנון."}
				</a>
				<br />

				<br />

				<b
					style={{
						fontSize: "19px",
						display: "block",
					}}>{`שליש גן עדן - מחתנים הכי הרבה זוגות בישראל - בהתחייבות!`}</b>

				<br />
			</div>
		);
	};

	useEffect(async () => {
		const res = await tokenService.getUserTokenData();
		setHasToken(!!res.data?.success);

		if (res.data?.success) {
			setTokenData(res.data?.data || {});
		}
	}, []);

	useEffect(() => {
		if (!ref.current) {
			ref.current = true;
		} else {
			if (purchasePromoteStatus === ApiRequestStatus.SUCCESSFULLY) {
				closePopup();
			}
		}
	}, [purchasePromoteStatus]);

	useEffect(() => {
		if (openCardcom) {
			dispatch(
				purchasePromotionWithCardcom(promotionSalePlan, () =>
					showFittedMessagePopup(textFitted, false)
				)
			);
			setShowIframe(true);
		}
	}, [openCardcom]);

	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className={showIframe ? "subscriptionDialog" : "simpleDialog buyWithToken"}>
			{!showIframe ? (
				<>
					{fetchCardcomApiStatus === ApiRequestStatus.PENDING && <Loader />}
					<div className="user-chat-nav">
						<Button
							color="none"
							type="button"
							onClick={closePopup}
							className="nav-btn"
							id="user-profile-hide">
							<ArrowBackIosNewIcon style={{ color: "#fff", transform: "rotate(180deg)" }} />
						</Button>
					</div>
					<Box className="colorHeader">
						<img src={whiteLogo} alt="" height={"50px"} />
					</Box>

					<ModalHeader style={{ marginTop: "20px" }}>{getHeaderText()}</ModalHeader>
					<ModalBody style={{ flex: "1", padding: "1.5rem" }}>{getBodyText()}</ModalBody>

					<ModalFooter style={{ display: "flex", flexDirection: "column", marginBottom: "56px" }}>
						<Button
							outline
							size="sm"
							color="primary"
							style={{
								width: "100%",
								minWidth: "fit-content",
								maxWidth: "500px",
							}}
							onClick={onContinuePurchase}
							className="btn-rounded approvePurchesBtn"
							disabled={isNil(fetchCardcomApiStatus !== ApiRequestStatus.PENDING)}>
							{`${gender ? "רכשי" : "רכוש"} עכשיו את הBoost במבצע!`}
						</Button>
					</ModalFooter>
				</>
			) : (
				<ModalBody>
					<IframeContainer
						apiStatus={fetchCardcomApiStatus}
						url={cardComUrl}
						onBack={() => setShowIframe(false)}
					/>
				</ModalBody>
			)}
		</Modal>
	);
}
