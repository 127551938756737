import i18next from "i18next";
import { Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { JaaSMeeting } from "@jitsi/react-sdk";

import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import AccountPictures from "components/account/AccountPictures";
import UploadImage from "components/login/UploadImage";
import useNavigator from "hooks/useNavigator";
import titleImage from "assets/images/forceUpload/titleForce.png";
import cards from "assets/images/forceUpload/cards.png";
import Button from "components/Button";
import { getIsDesktopWeb } from "redux/init/selectors";
import newLogo from "assets/images/newLogin/newLogo2.png";

export default () => {
	const { closePopup } = usePopups();
	const { data } = useSelector(getPopups);
	const navigate = useNavigator();
	const [loading, setLoading] = useState(false);
	const isPlatformWeb = useSelector(getIsDesktopWeb);

	const navigateToUploadImage = () => {
		closePopup();
		navigate.navigateToAccountPic();
	};
	return (
		<Modal
			isOpen={true}
			toggle={closePopup}
			className="forceUploadDialog "
			style={{ margin: isPlatformWeb ? "50px auto" : "50px 20px", maxWidth: "450px" }}>
			<ModalBody>
				<Box>
					<Box
						sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
						לא רואים אותך ב<img style={{ margin: "50px 0 20px 0" }} src={newLogo}></img>
					</Box>
					<h1>{`${data?.name}, לא חבל?`}</h1>
					<Box
						sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
						<img src={cards} style={{ marginTop: "40px" }}></img>
					</Box>
					<h2 className="desc">
						משתמשים בלי תמונה לא מופיעים בתוצאות החיפוש, ולא יכולים לבצע פעולות באפליקציה.
						<b> זה לוקח פחות מחצי דקה להעלות תמונה ופשוט להכיר!</b>
					</h2>
					<Box
						className="wrapMainBtn"
						style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>
						{!!loading ? (
							<CircularProgress size={40} className="text-secondary" />
						) : (
							<Button
								className="mainAccountBtn"
								onClick={navigateToUploadImage}
								text={"העלאת תמונה"}
							/>
						)}
					</Box>
				</Box>
				{/* <UploadImage onChange={handleOnChange} onValidityChange={handleOnValidityChange} /> */}
			</ModalBody>
		</Modal>
	);
};
