import { Link } from "react-router-dom";
import arrowD4d from "assets/images/arrowD4d.svg";
import logoImg from "assets/images/icons/logoSmart.png";
import newLogo2 from "assets/images/newLogin/newLogo2.png";

export default function ChanelHeader({ closePopup }) {
	return (
		<div className="chanelHeaderWrapper">
			<div className="d-block d-lg-none me-2 ms-0">
				<Link to="#" onClick={closePopup} className="user-chat-remove text-muted">
					<img src={arrowD4d} alt="" height={"30px"} />
				</Link>
			</div>
			<div className={"chat-user-img align-self-center me-3 ms-0"}>
				<img
					src={newLogo2}
					style={{ cursor: "pointer", height: "3rem", width: "3rem" }}
					className="rounded-circle avatar-sm"
				/>
			</div>
			<h5 className="name">
				<span className="headName">{"צוות שליש גן עדן "}</span>
				<span
					className="userDetailsChatMobile age text-truncate font-size-12 mb-1"
					style={{ textAlign: "start" }}>{`הצעות והתאמות`}</span>
			</h5>
		</div>
	);
}
