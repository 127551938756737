import { useEffect, useState } from "react";
import LocalStorageService from "services/localStorage";
import moment from "moment";
import returnIcon from "assets/images/newLogin/return.svg";

export default ({ onClick }) => {
	const [comeFromOldSite, setComeFromOldSite] = useState(false);
	const comeFromOldSiteCookie = LocalStorageService.get("comeFromOldSite");

	useEffect(() => {
		if (comeFromOldSiteCookie) {
			const timestamp = parseInt(comeFromOldSiteCookie);
			const now = moment();
			const pastTimeInMilliseconds = now.diff(moment(timestamp));
			if (pastTimeInMilliseconds < 60 * 1000) {
				setComeFromOldSite(true);
			}
		}
	}, [comeFromOldSiteCookie]);

	useEffect(() => {
		setTimeout(() => {
			setComeFromOldSite(false);
		}, 30000);
	}, [comeFromOldSite]);

	return (
		<>
			{!!comeFromOldSite && (
				<>
					<div className="floating-old-site" onClick={onClick}>
						<img src={returnIcon} />
						<a href="https://www.date4dos.co.il/?login_act=phone" target="_blank">
							להתחברות לפלטפורמה הישנה
						</a>
					</div>
					<div className="floating-old-site-mobile" onClick={onClick}>
						<div className="sticky-bottom">
							<img src={returnIcon} />
							<a href="https://www.date4dos.co.il/?login_act=phone" target="_blank">
								להתחברות לפלטפורמה הישנה
							</a>
						</div>
					</div>
				</>
			)}
		</>
	);
};
