import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Sidebar from "views/Sidebar";
import Content from "views/Content";
import SearchFilter from "components/search/SearchFilter";
import TopBar from "components/search/TopBar";
import SearchContent from "components/search/SearchContent";
import Loader from "components/Loader";
import { getUserProfile } from "redux/profile/selectors";
import BottomStrip from "components/BottomStrip";
import Dimmer from "components/Dimmer";
import isMobile from "helpers/isMobile";
import searchTypes from "consts/typesSearch";
import { useScrollDirection } from "../hooks/useScrollDirection";
import LocalStorageService from "services/localStorage";
import FloatingGoUpButton from "./FloatingGoUpButton";
import useNavigator from "hooks/useNavigator";
import ProfileService from "services/profile";
import AppPlatforms from "consts/AppPlatforms";
import AppService from "services/app";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getIsDesktopWeb } from "redux/init/selectors";
import FloatingOldSite from "./FloatingOldSite";

export default ({ type = searchTypes.Search }) => {
	const [showSearchFilterSlide, setShowSearchFilterSlide] = useState(false);
	const [searchLocation, setSearchLocation] = useState();
	const [showFloatingGoUpButton, setShowFloatingGoUpButton] = useState(false);
	const [showFilterInMobile, setShowFilterInMobile] = useState(false);
	const [ready, setReady] = useState(false);
	const [pageYOffset, setPageYOffset] = useState(window.pageYOffset);
	const navigator = useNavigator();
	const currentUser = useSelector(getUserProfile);
	const isUserLoggedIn = !isEmpty(currentUser);
	const searchPageRef = useRef();
	const isDesktopWeb = useSelector(getIsDesktopWeb);

	const history = useHistory();
	const currentPath = history.location.pathname;

	const scrollToTop = () => {
		if (searchPageRef && searchPageRef.current && currentPath !== "/")
			searchPageRef.current.scrollIntoView();
	};

	//NAVBAR
	const navRef = useRef(null);
	const scrollDirection = useScrollDirection(window);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setPageYOffset(window.pageYOffset);
		});
	}, []);

	useEffect(() => {
		scrollToTop();
		setTimeout(() => {
			setReady(true);
		}, 300);
	}, []);

	useEffect(() => {
		if (currentUser.uid && !currentUser?.isReferToCompleteLogin) {
			ProfileService.updateProfile(currentUser.uid, {
				isReferToCompleteLogin: true,
			});
			setTimeout(() => {
				navigator.navigateToLoginQuestionsPage();
			}, 120000);
		}
	}, [currentUser]);

	useEffect(() => {
		if (!!navRef?.current?.classList) {
			if (scrollDirection == "down") {
				navRef.current.classList.remove("downSlide");
				navRef.current.classList.add("upSlide");
				setShowFloatingGoUpButton(true);
			} else if (pageYOffset < 50) {
				navRef.current?.classList.remove("upSlide");
				navRef.current.classList.add("downSlide");
				setShowFloatingGoUpButton(false);
			}
		}
	}, [scrollDirection, pageYOffset]);

	useEffect(() => {
		if (!!navRef?.current?.classList) {
			if (scrollDirection == "up") {
				navRef.current.classList.remove("upSlide");
				navRef.current.classList.add("downSlide");
			}
		}
	}, [scrollDirection]);

	const isFilterTimeExpired = () => {
		const lastFilterTime = Number(LocalStorageService.get("lastFilterTime"));
		const oneMonthAgo = new Date().setMonth(new Date().getMonth() - 1);
		const lastFilterTimeIsMoreThanMonth =
			lastFilterTime && moment(lastFilterTime).isBefore(moment(oneMonthAgo));
		return !lastFilterTime || lastFilterTimeIsMoreThanMonth;
	};

	useEffect(() => {
		// const isFilterExpired = isFilterTimeExpired();
		// if (isFilterExpired) {
		// 	setShowSearchFilterSlide(true);
		// 	LocalStorageService.set("lastFilterTime", Date.now());
		// }
	}, []);

	/* useEffect(() => {
		//if (profile?.uid && !profile?.isTester) navigator.navigateToSoon();

		AppService.getPlatform().then((platform) => {
			if (platform !== AppPlatforms.IOS) {
				if (currentUser?.uid && !currentUser?.isTester && false) navigator.navigateToSoon();
			}
		});
	}, [currentUser?.uid]); */

	useEffect(() => {
		if (
			!isDesktopWeb &&
			currentUser?.uid &&
			!currentUser?.isEnteredSearch &&
			currentPath === "/search"
		) {
			setShowFilterInMobile(true);
			ProfileService.updateProfile(currentUser.uid, {
				isEnteredSearch: true,
			});
		}
	}, [isUserLoggedIn]);

	return ready ? (
		<>
			{((isUserLoggedIn && type !== searchTypes.Matches) ||
				(currentPath === "/" && !isUserLoggedIn)) && (
				<Sidebar
					className={`sideBarSearchFilter ${showSearchFilterSlide ? "showSearchFilterSlide" : ""}`}>
					<SearchFilter
						scrollToTop={scrollToTop}
						searchLocation={searchLocation}
						setSearchLocation={setSearchLocation}
					/>
				</Sidebar>
			)}
			<div className="toBlur"></div>
			<TopBar
				scrollToTop={scrollToTop}
				navRef={navRef}
				className={type !== searchTypes.Matches ? "downSlide" : "downSlide mathesTopBar"}
				typeSearch={type}
				currentUser={currentUser}
				showSearchBar={type !== searchTypes.Matches}
				showSearcFilterhBarOnEnterPage={showFilterInMobile}
			/>

			<div
				className={`searchPage ${type === searchTypes.Matches ? "matchesPage" : ""}`}
				ref={searchPageRef}>
				<Content>
					<SearchContent searchLocation={searchLocation} />
					{isMobile() && <BottomStrip />}
				</Content>
			</div>
			{showFloatingGoUpButton && <FloatingGoUpButton onClick={scrollToTop} />}
			{<FloatingOldSite onClick={() => {}} />}
			<Dimmer />
		</>
	) : (
		<Loader />
	);
};
