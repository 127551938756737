import i18next from "i18next";
import { Button, PopoverBody, PopoverHeader, Popover } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import Collapse from "./Collapse";
import isMobile from "helpers/isMobile";
import { useState } from "react";
import { addTagByText } from "redux/profile/actions";
import usePopups from "hooks/usePopups";
import { search } from "redux/search/actions";
import useNavigator from "hooks/useNavigator";
import { getUserProfile } from "redux/profile/selectors";
import _ from "lodash";
import { isArray } from "validate.js";

export default ({ profile, isCardModal }) => {
	const [isMobileDevice] = useState(isMobile());
	return (
		<>
			{(profile.aboutMe || !isMobileDevice) && (
				<Collapse title={i18next.t("About Me")} collapsHeight={45}>
					{profile.aboutMe}
				</Collapse>
			)}
			{isCardModal && profile.tags ? (
				<Collapse
					title={profile.aboutMe ? "" : i18next.t("About Me")}
					collapsHeight={98}
					className="tagsofProfile">
					<Tags profile={profile} />
				</Collapse>
			) : (
				profile.tags && (
					<Tags
						profile={profile}
						tags={isArray(profile.tags) ? profile.tags : Object.values(profile.tags)}
					/>
				)
			)}

			{profile.oneLiner && <h5 className="oneLiner">"{profile.oneLiner}"</h5>}
			{(profile.aboutSpouse || !isMobileDevice) && (
				<Collapse title={i18next.t(`About Spouse_${profile.gender}`)} collapsHeight={45}>
					{profile.aboutSpouse}
				</Collapse>
			)}
		</>
	);
};

const Tags = ({ profile, tags }) => {
	return (
		<>
			<span style={{ fontSize: "17px", fontWeight: "bold", color: "#2d5086" }}>תחומי עניין</span>
			<div className="wrapSumBtn">
				{tags?.map((tag, index) => (
					<ButtonWithPopover tag={tag} targetId={`tag${profile.uid + index}`} />
				))}
			</div>
		</>
	);
};

const ButtonWithPopover = ({ tag, targetId }) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const dispatch = useDispatch();
	const currentUser = useSelector(getUserProfile) || {};
	const { showTagAddedSuccessfullyPopup, _closePopupUnderPopup, _closeSecondPopupUnderPopup } =
		usePopups();
	const navigator = useNavigator();

	const addTag = () => {
		if (
			!!currentUser?.tags &&
			!_.isEmpty(currentUser?.tags) &&
			!!Object.values(currentUser?.tags).includes(tag)
		) {
			return toggle();
		}

		dispatch(addTagByText(tag, showTagAddedSuccessfullyPopup));
		toggle();
	};

	const searchTag = () => {
		toggle();
		_closePopupUnderPopup();
		_closeSecondPopupUnderPopup();
		dispatch(search(tag));
		navigator.navigateToSearchPage({ searchTag: true });
	};

	return (
		<>
			<Button id={targetId} outline size="sm" color="primary" className="btn-rounded tagStyle">
				{tag}
			</Button>
			<Popover
				className="userTagsPopover"
				isOpen={isOpen}
				placement="top"
				target={targetId}
				trigger="legacy"
				toggle={toggle}>
				<PopoverHeader>
					<span className="tagsPopoverHeader">
						{i18next.t("Edit Profile.What do you want to do with the tag")}
					</span>
					<Button
						color="none"
						type="button"
						onClick={toggle}
						className="nav-btn closeButton"
						id="user-profile-hide">
						<i className="ri-close-line"></i>
					</Button>
				</PopoverHeader>
				<PopoverBody>
					<Button
						size="sm"
						color="primary"
						onClick={searchTag}
						className="btn-rounded littleBtn tagsButtonPop searchTag">
						<div className="innerBtnTxt">
							{i18next.t("Edit Profile.Search with the tag")}
							<span className="tagText">{tag}</span>
						</div>
					</Button>
					{!Object.values(currentUser?.tags || {}).includes(tag) && (
						<Button
							size="sm"
							color="primary"
							onClick={addTag}
							className="btn-rounded littleBtn tagsButtonPop addTag">
							<div className="innerBtnTxt">
								{i18next.t("Edit Profile.Add the tag 1")}
								<span className="tagText">{tag}</span>
								{i18next.t("Edit Profile.Add the tag 2")}
							</div>
						</Button>
					)}
				</PopoverBody>
			</Popover>
		</>
	);
};
