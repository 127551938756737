import { useEffect } from "react";
import { useSelector } from "react-redux";

import HomePage from "components/HomePage";
import isMobile from "helpers/isMobile";
import { getIsAuthChecked } from "redux/auth/selectors";
import NewHomePage from "components/NewHomePage";

export default ({ children }) => {
	const isAuthChecked = useSelector(getIsAuthChecked);

	useEffect(() => {
		if (!isMobile()) return;
		const heightW = document.documentElement.clientHeight;
		const loginHolder = document.querySelector(".loginHolder");

		loginHolder && (loginHolder.style.height = heightW + "px");
	}, [isAuthChecked, children]);

	return (
		<div>
			<NewHomePage className="homeAsBG" />
			{/* {!isMobile && <HomePage onSubmit={() => {}} onMobileSubmit={() => {}} hasError={false} />} */}
			{children}
		</div>
	);
};
